import react, {useEffect, useRef} from 'react';

const Banner160x600 : React.FC = () => {
    const adConationerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const scriptContent = `
        atoptions = {
           'key': '13cdfa0a72d2f42850afdb86d4310fbe',
           'format': 'iframe',
           'height' : 600,
           'width': 160,
            params : {}
      };`

      const scriptElement = document.createElement('script')
      scriptElement.type = "text/javascript"
      scriptElement.innerHTML = scriptContent
      document.body.appendChild(scriptElement);

      const scriptSrc = document.createElement('script')
      scriptSrc.type = "text/javascript"
      scriptSrc.src = '//www.highperformancedformats.com/13cdfa0a72d2f42850afdb86d4310fbe/invoke.js'
      document.body.appendChild(scriptSrc)


      return () => {
          document.body.removeChild(scriptElement)
          document.body.removeChild(scriptSrc)
      }
  }, [])

  return (
      <>
        <h1></h1>
        <div
             id='!ad-container'
             ref={adConationerRef}
    >
        
        </div>
      </>
  )
  
}

export default Banner160x600;
