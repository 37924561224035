import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { InformationCircleIcon, ChartBarIcon } from "@heroicons/react/outline";
import { LightBulbIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import { Alert } from "./components/alerts/Alert";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { AboutModal } from "./components/modals/AboutModal";
import { InfoModal } from "./components/modals/InfoModal";
import { WinModal } from "./components/modals/WinModal";
import { LoseModal } from "./components/modals/LoseModal";
import { StatsModal } from "./components/modals/StatsModal";
import { CreateModal } from "./components/modals/CreateModal";
import Banner from "./components/banner/Terra_banner";
import Minha from "./Minha";
import { isWordInWordList, isWinningWord, solutionIndex, GlobalVars } from "./lib/words";
import { addStatsForCompletedGame, loadStats } from "./lib/stats";
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setAlertaLocalStorage,
} from "./lib/localStorage";

const Main = () => {
  return (
  <Router>
    <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/d/:id">
            <Home />
          </Route>
          <Route path="/desafie">
            <CreateModal />
          </Route>
          <Route path="/desafio">
            <CreateModal />
          </Route>
        </Switch>
    </Router>
  );
}

var solution = '';
var solutionw = '';

function Home() {
var [ nome ] = useState("");
var [ palavra ] = useState("");

const { id } = useParams() as any;

var encodedString = atob(id).split(":");

nome = encodedString[1];
palavra = encodedString[0];

if (palavra.length === 5){
  return (
    <Minha
    nome={ nome }
    palavra={ palavra }
    sharelink = { id }
    />
  );
}else{
	return(
	<Alert message="DESAFIO NÃO ENCONTRADO" isOpen={true} />
	);

}

}

function App() {
  const [currentGuess, setCurrentGuess] = useState("");
  const [isGameFinished] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const [isWinModalOpen, setIsWinModalOpen] = useState(false);
  const [isLoseModalOpen, setIsLoseModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false);
  const [isWordNotLengthAlertOpen, setWordNotLengthAlertOpen] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [shareComplete, setShareComplete] = useState(false);
  const [guesses, setGuesses] = useState<string[]>(() => {
		const loaded = loadGameStateFromLocalStorage();
                solutionw = GlobalVars.solution_w;
		
		if (loaded?.solution === GlobalVars.solution){
			solution = GlobalVars.solution;
			if (loaded.guesses.includes(solution)) {
		    setIsGameWon(true);
		  }				
			return loaded.guesses;
		}
		else if (loaded?.solution.length === 5) {
			if(loaded?.guesses.length > 0 && loaded?.guesses.length < 6){
				if (loaded.guesses.includes(loaded?.solution)) {
					solution = GlobalVars.solution;
					return [];
				}
				else {
					solution = loaded?.solution;
                                        solutionw = loaded?.solutionw;
					GlobalVars.solution = loaded?.solution;
					return loaded.guesses;
				}
			}
			else{
				solution = GlobalVars.solution;
				return [];			
			}
		}
		else {
			solution = GlobalVars.solution;
			return [];
		}

		
  });
  
  const [stats, setStats] = useState(() => loadStats());

  useEffect(() => {
    saveGameStateToLocalStorage("CharadaState", { guesses, solution, solutionw });
  }, [guesses]);

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true);
    }
  }, [isGameWon]);
  
  useEffect(() => {
    setAlertaLocalStorage();
  }, []);
  
  
  const toogle = () => {
  
    if (localStorage.theme === 'dark'){
    	localStorage.theme = 'light'
    } else {
    	localStorage.theme = 'dark'    
    }
    
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
		document.documentElement.classList.add('dark')
    } else {
		document.documentElement.classList.remove('dark')
    }
  };

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (isGameWon) {
        setIsWinModalOpen(true);
    }
    else if (guesses.length === 6 && !isGameWon){
    	  setIsLoseModalOpen(true);
    }
    else {
        if (currentGuess.length < 5 ) {
            setWordNotLengthAlertOpen(true);
        return setTimeout(() => {
            setWordNotLengthAlertOpen(false);
          }, 4000);
        }
        
        if (!isWordInWordList(currentGuess)) {
          setIsWordNotFoundAlertOpen(true);
          return setTimeout(() => {
            setIsWordNotFoundAlertOpen(false);
          }, 4000);

    	}
 
    }

    const winningWord = isWinningWord(currentGuess);

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess("");

      if (winningWord) {
      	setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true); 
      }

      if (guesses.length === 5) {
      	setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsLoseModalOpen(true);
      }
    }
  };

  return (
  <div className="flex flex-col h-screen bg-slate-100 dark:bg-slate-900 mx-auto lg:flex-row">
  <div className="flex-none hidden lg:inline p-2 w-170"><p>&nbsp;</p><img width="160" height="600" src="banner_160_600.png" /></div>

    <div className="grow max-w-7xl">	  
    <div className="flex flex-col h-screen pt-3 sm:px-6 lg:px-8">
      <Alert message="Palavra não encontrada" isOpen={isWordNotFoundAlertOpen} />
      <Alert message="Insira uma palavra com 5 letras" isOpen={isWordNotLengthAlertOpen} />
      <Alert
        message={`You lost, the word was: ${GlobalVars.solution_w}`}
        isOpen={isGameLost}
      />
      <Alert
        message="Resultado copiado para área de transferência"
        isOpen={shareComplete}
        variant="success"
      />
      <div className="flex w-80 mx-auto items-center mb-3 pt-2 px-2">
        <div className="flex-none">
        <LightBulbIcon
          className="h-6 w-6 cursor-pointer dark:text-gray-300"
          onClick={toogle}
        />
            
        </div>
        <div className="flex-none">
        <ChartBarIcon
          className="h-6 w-6 cursor-pointer dark:text-gray-300"
          onClick={() => setIsStatsModalOpen(true)}
        />
        </div>    
        <div className="flex-grow">
        <h1 className="text-3xl grow font-bold text-center font-mono dark:text-slate-200 text-gray-700">CHARADA ∞</h1>
        </div>
        <div className="flex-none w-6">
        </div>
        <div className="flex-none">
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:text-gray-300"
          onClick={() => setIsInfoModalOpen(true)}
        />
        </div>
      </div>
      
      
		  
		  	<Grid guesses={guesses} currentGuess={currentGuess} />
		  	<Keyboard
		    onChar={onChar}
		    onDelete={onDelete}
		    onEnter={onEnter}
		    guesses={guesses}
		  />
	
		  
		  
		  <WinModal
		    isOpen={isWinModalOpen}
		    handleClose={() => setIsWinModalOpen(false)}
		    guesses={guesses}
		    gameStats={stats}
		    handleShare={() => {
		      setIsWinModalOpen(false);
		      setShareComplete(true);
		      return setTimeout(() => {
		        setShareComplete(false);
		      }, 4000);
		    }}
		  />
		  <LoseModal
		    isOpen={isLoseModalOpen}
		    handleClose={() => setIsLoseModalOpen(false)}
		    guesses={guesses}
		    solution_w={solutionw}
		    handleShare={() => {
		      setIsLoseModalOpen(false);
		      setShareComplete(true);
		      return setTimeout(() => {
		        setShareComplete(false);
		      }, 4000);
		    }}
		  />
		  <InfoModal
		    isOpen={isInfoModalOpen}
		    handleClose={() => setIsInfoModalOpen(false)}
		  />
		  <StatsModal
		    isOpen={isStatsModalOpen}
		    handleClose={() => setIsStatsModalOpen(false)}
		    gameStats={stats}
		  />
		  <AboutModal
		    isOpen={isAboutModalOpen}
		    handleClose={() => setIsAboutModalOpen(false)}
		  />
		  

    </div>
    	  </div>
    	  
	
    <div className="flex-basis hidden lg:inline p-2 w-170 text-center">
    <p>Publicidade</p>
	<Banner />
    </div>
</div>
  );
}

export default Main;
