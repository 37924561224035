import react, {useEffect, useRef} from 'react';

export default function Banner(): JSX.Element {
    const banner = useRef<HTMLDivElement>(null)

    const atOptions = {
        key: '13cdfa0a72d2f42850afdb86d4310fbe',
        format: 'iframe',
        height: 600,
        width: 160,
        params: {},
    }
    useEffect(() => {
    if (banner.current && !banner.current.firstChild) {
        const conf = document.createElement('script')
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `//www.highperformanceformat.com/${atOptions.key}/invoke.js`
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

        banner.current.append(conf)
        banner.current.append(script)
    }
}, [banner])

    return <div ref={banner}></div>
}